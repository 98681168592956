import { Component } from "react";
import { post } from "../api";
import { FlashMessage } from "./FlashMessage";
import { Checkbox, FileUploader, Submit } from "./Form";
import { withRouter } from "../navigation";
import { JobStartupStatusModal } from "./Modal";

export class NewJob extends Component {
  state = {
    loading: 0,
    selectedFile: null,
    emailToNotify: '',
    extractQuickOutput: true,
    buildCompleteModel: true,
    extractCompleteOutput: true,
    useBusinessOnly: true,
    useHistoricalData: false,
    canRegularUserDownloadResults: false,
    succeeded: false, errorMessage: null,
    enrichmentComparedStatistics: null,
    submitted: false, jobId: null, analysisStarted: false
  }
  handleFileChange = file => this.setState({selectedFile: file})
  async submit() {
    this.setState({errorMessage: null});
    if (this.state.selectedFile === null) {
      this.setState({errorMessage: 'No file selected.'});
      return false;
    }
    if (this.state.selectedFile.size > 1024 * 1024 * 50) {
      this.setState({errorMessage: 'File too large. Allowed size is up to 50 MB.'});
      return false;
    }
    this.setState({submitted: true});
    const body = new FormData();
    if (this.state.buildCompleteModel && this.state.extractCompleteOutput && this.state.emailToNotify) {
      body.append('emailtonotify', this.state.emailToNotify);
    }
    body.append(
      'extractquickoutput', this.state.extractQuickOutput
    );
    body.append(
      'validatequickmodel', this.state.buildCompleteModel
    );
    body.append(
      'extractcompleteoutput',
      this.state.buildCompleteModel && this.state.extractCompleteOutput
    );
    body.append('use_business_only', this.state.useBusinessOnly);
    body.append('use_historical_data', this.state.useHistoricalData);
    body.append(
      'can_regular_user_download_results',
      this.state.canRegularUserDownloadResults
    );
    body.append('data', this.state.selectedFile);
    let jobId = null;
    try {
      jobId = (await (await post({path:'job/', body})).json()).jobId;
    } catch {
      this.setState({errorMessage: 'Server error occurred.'});
      return;
    }
    this.setState({jobId});
  }
  render = () => <>
    <JobStartupStatusModal
      submitted={this.state.submitted} jobId={this.state.jobId}
      analysisStarted={this.state.analysisStarted}
      comparedStatistics={this.state.enrichmentComparedStatistics}
      routerFeatures={this.props.routerFeatures}
    />
    <h3>Create a New Job</h3>
    <FlashMessage type="danger" children={this.state.errorMessage} />
    <FileUploader
      setSelectedFile={file => this.setState({selectedFile: file})}
      disabled={this.state.loading}
    />
    <div className="text-muted mb-3">
      <em>Recommended input size between 500 and 10,000 domains.</em>
    </div>
    <Checkbox
      value={this.state.extractQuickOutput}
      onValueChange={value => this.setState({extractQuickOutput: value})}
      label="Extract Quick Output" disabled={this.state.loading}
    />
    <Checkbox
      value={this.state.buildCompleteModel}
      onValueChange={value => this.setState({buildCompleteModel: value})}
      label="Build Complete Model" disabled={this.state.loading}
    />
    <Checkbox
      value={this.state.buildCompleteModel && this.state.extractCompleteOutput}
      onValueChange={value => this.setState({extractCompleteOutput: value})}
      disabled={!this.state.buildCompleteModel || this.state.loading}
      label="Extract Complete Output"
    />
    <div className="d-flex my-4">
      <div className="me-5">
        <Checkbox
          value={this.state.useBusinessOnly}
          onValueChange={value => this.setState({useBusinessOnly: value})}
          disabled={this.state.loading}
          label="Use Business Domains Only"
        />
        <div className="text-muted mb-3">
          <em>
            <div>If selected, we will build model using 100M domains classified as verified businesses.</div>
            <div>Otherwise, system scans entire 300M domains collected as DBI dataset.</div>
          </em>
        </div>
      </div>
      <div className="me-5">
        <Checkbox
          value={this.state.useHistoricalData}
          onValueChange={value => this.setState({useHistoricalData: value})}
          disabled={this.state.loading}
          label="Use Historical Data"
        />
        <div className="text-muted mb-3">
          <em>
            <div>If selected, we will build model using all historical data.</div>
            <div>Otherwise, system scans only recent DBI snapshot.</div>
          </em>
        </div>
      </div>
    </div>
    <div className="form-floating my-3">
      <input
        id="email" type="email" className="form-control"
        placeholder="E-mail address to notify" disabled={this.state.loading}
        value={this.state.emailToNotify}
        onChange={e => this.setState({emailToNotify: e.target.value})}
      />
      <label htmlFor="email">E-mail address to notify</label>
    </div>
    <div className="mb-4">
      <Checkbox
        value={this.state.canRegularUserDownloadResults}
        onValueChange={value => this.setState({canRegularUserDownloadResults: value})}
        disabled={this.state.loading}
        label="Can Regular User Download Results"
      />
    </div>
    <Submit
      className="d-block" loading={this.state.loading}
      onClick={() => this.submit()}
      setLoading={() => null && this.setState(previousState => ({loading: previousState.loading + 1}))}
      unsetLoading={() => null && this.setState(previousState => ({loading: previousState.loading - 1}))}
    />
  </>
}

export const NewJobWithRouter = withRouter(NewJob);

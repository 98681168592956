import { Component } from 'react';
import { BACKENDURL, getJson } from '../api';
import { FlashMessage } from './FlashMessage';
import { Loadable } from './Loadable';
import { withRouter } from '../navigation';
import { DownloadIcon } from './Icons';
import { ModelStatusBadge } from './StatusBadge';
import { PredictorsOverview } from './PredictorsOverview';
import { PercentageProgressBar, QuickModelStatistics } from './QuickModelStatistics';
import { Results } from './Results';
import { areResultsDownloadable } from '../results';
import { JobAdministration } from './JobAdministration';

class JobTitle extends Component {
  downloadUrl() {
    if (process.env.REACT_APP_DEPLOY_MODE !== 'admin') {
      return `${BACKENDURL}/job/${this.props.jobMeta?.id}/completeoutput/results/file`;
    }
    return `${BACKENDURL}/job/${this.props.jobMeta?.id}/completeoutput/results/file/admin`;
  }
  render = () => <header>
    <div className="d-flex">
      <h3 className="flex-fill">{this.props.jobMeta?.filename}</h3>
      <div className="me-3">
        <div className="d-flex">
          <div className="flex-fill" />
          <div>Quick Model</div>
        </div>
        <div className="d-flex">
          <div className="flex-fill" />
          <div>
            {this.props.jobMeta?.models?.quick && <ModelStatusBadge model={this.props.jobMeta?.models?.quick} />}
          </div>
        </div>
      </div>
      <div className="me-3">
        <div className="d-flex">
          <div className="flex-fill" />
          <div>Complete Model</div>
        </div>
        <div className="d-flex">
          <div className="flex-fill" />
          <div>
            {this.props.jobMeta?.models?.complete && <ModelStatusBadge model={this.props.jobMeta?.models?.complete} />}
          </div>
        </div>
        {
          this.props.jobMeta?.models.complete.progress && <div className="mt-1">
            <PercentageProgressBar
              value={this.props.jobMeta?.models.complete.progress}
              color="info"
            />
          </div>
        }
      </div>
      {
        areResultsDownloadable(this.props.jobMeta) && <div>
          <a className="btn btn-danger btn-lg" href={this.downloadUrl()}>
            Download Complete Results <DownloadIcon />
          </a>
        </div>
      }
    </div>
  </header>
}



const JobOverview = ({jobId, jobMeta, navigate, synchronizeJobMeta}) => <>
  <ul className="nav nav-tabs">
    <li className="nav-item active">
      <button
        className="nav-link active"
        data-bs-toggle="tab"
        data-bs-target="#tab-quick-model"
      >Quick Model</button>
    </li>
    <li className="nav-item">
      <button
        className={`nav-link ${jobMeta.models.complete.label !== "OUTPUT BUILT" ? 'disabled' : ''}`}
        data-bs-toggle="tab"
        data-bs-target="#tab-results"
      >Results</button>
    </li>
    {
      process.env.REACT_APP_DEPLOY_MODE === 'admin' && <li className="nav-item">
        <button
          className="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#tab-administration"
        >Administration</button>
      </li>
    }
  </ul>
  <div className="tab-content p-2">
    <div id="tab-quick-model" className="tab-pane fade show active">
      <div className="d-flex align-items-start">
        <div className="nav flex-column nav-pills me-5" style={{minWidth: 180}}>
          <button
            className="nav-link active"
            data-bs-toggle="pill"
            data-bs-target="#tab-quick-model-statistics"
          >Statistics</button>
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#tab-quick-model-predictors"
          >Predictors</button>
        </div>
        <div className="tab-content">
          <div
            id="tab-quick-model-statistics"
            className="tab-pane fade show active"
          >
            {
              ['MODEL BUILT', 'BUILDING OUTPUT', 'OUTPUT BUILT'].includes(
                jobMeta?.models.quick.label
              ) && <QuickModelStatistics jobId={jobId} />
            }
          </div>
          <div
            id="tab-quick-model-predictors"
            className="tab-pane fade show"
          >
            <PredictorsOverview
              jobId={jobId}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
    </div>
    <div id="tab-results" className="tab-pane fade">
      {
        jobMeta?.models.complete.label === 'OUTPUT BUILT' &&
          <Results jobId={jobId} jobMeta={jobMeta} />
      }
    </div>
    <div id="tab-administration" className="tab-pane fade">
      <JobAdministration
        jobId={jobId}
        jobMeta={jobMeta}
        onSave={synchronizeJobMeta}
      />
    </div>
  </div>
</>;

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {jobMeta: null, loading: 1};
  }
  async componentDidMount() {
    const jobId = this.props.routerFeatures.params.jobId;
    while (true) {
      const path = `job/${jobId}/meta/${this.state.jobMeta?.metaHash}/`;
      const jobMeta = await getJson({path, asAdmin: false});
      this.setState({jobMeta, loading: 0});
    }
  }
  synchronizeJobMeta = ({canRegularUserDownloadResults}) => this.setState(
    previousState => ({...previousState, canRegularUserDownloadResults})
  )
  render = () => <Loadable label="Job Meta" loading={this.state.loading}>
    {
      this.state.jobMeta ? <>
        <JobTitle jobMeta={this.state.jobMeta} />
        <FlashMessage
          children={this.props.routerFeatures.location.state?.successMessage}
          type="success"
        />
        <JobOverview
          jobId={this.props.routerFeatures.params.jobId}
          jobMeta={this.state.jobMeta}
          navigate={this.props.routerFeatures.navigate}
          synchronizeJobMeta={this.synchronizeJobMeta}
        />
      </> : <h4>Couldn't get the job.</h4>
    }
  </Loadable>
}

export const JobWithRouter = withRouter(Job);

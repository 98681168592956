import { Popover } from "bootstrap";
import { Component } from "react";
import { Converter } from "showdown";
import { Checkbox } from "./Form";

const PredictorHeader = ({predictors}) => <tr>
  <th className="table-col-label">Label</th>
  {
    predictors?.header.map(
      title => <th
        key={`header-${title.toLowerCase()}`}
        className={`table-col-${title.toLowerCase()}`}
      >{title}</th>
    )
  }
  {process.env.REACT_APP_DEPLOY_MODE === 'admin' && <th className="table-col-use">Use</th>}
</tr>;

class PredictorRow extends Component {
  constructor(props) {
    super(props);
    this.converter = new Converter({tables: true});
  }
  componentDidMount() {
    const row = this.props.row;
    const definition = row?.fields[0]?.definition;
    if (!definition) {
      return;
    }
    const name = row?.fields[0]?.value;
    const title = row?.label || name;
    const content = this.converter.makeHtml(definition || '').replace(
      '<table>',
      '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-label-${row?.group}-${name}`),
      {trigger: 'hover focus', title, content, html: true}
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-attribute-${row?.group}-${name}`),
      {trigger: 'hover focus', title, content, html: true}
    );
  }
  render = () => <tr>
    <td className="table-col-label">
      <span
        id={`popover-label-${this.props.row?.group}-${this.props.row?.fields[0]?.value}`}
        className="d-inline-block"
        title={this.props.row?.label || this.props.row?.fields[0]?.value}
      >
        {
          this.props.row?.excluded ?
            <del className="text-muted">{this.props.row?.label}</del> :
            this.props.row?.label
        }
      </span>
    </td>
    {
      this.props.row?.fields?.map(
        field => <td
          key={`row-${field.key}`} className={`table-col-${field.key}`}
          id={`popover-${field.key}-${this.props.row?.group}-${this.props.row?.fields[0]?.value}`}
          title={field.value}
        >
          {this.props.row?.excluded ? <del className="text-muted">{field.value}</del> : field.value}
        </td>
      )
    }
    {
      process.env.REACT_APP_DEPLOY_MODE === 'admin' && <td className="table-col-use">
        <Checkbox
          value={!this.props.row?.excluded}
          onValueChange={this.props.onSelectionChange}
          disabled={this.props.loading}
        />
      </td>
    }
  </tr>
}

export const PredictorTable = ({title, predictors, onExcludePredictor, loading}) => <section>
  <h4 className="border-bottom border-2">{title} Results</h4>
  <table className="table table-sm table-scrollable collapse show">
    <thead><PredictorHeader predictors={predictors} /></thead>
    <tbody style={{height: 170}}>
      {
        predictors?.rows.filter(row => row.group === title.toLocaleLowerCase()).map(
          (row, i) => <PredictorRow
            key={`${i}-${row?.fields[0].key}`} row={row} loading={loading}
            onSelectionChange={() => onExcludePredictor(row)}
          />
        )
      }
    </tbody>
  </table>
</section>;

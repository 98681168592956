import { Component } from "react";
import { Converter } from "showdown";
import { PencilIcon } from "./Icons";
import { Submit } from "./Form";
import { postJson } from '../api';

export class ExtraInformation extends Component {
  state = {editing: false, saving: false, error: null, draft: this.props.data}
  constructor(props) {
    super(props);
    this.converter = new Converter({tables: true});
  }
  isEditable = () => !this.state.editing && process.env.REACT_APP_DEPLOY_MODE === 'admin'
  edit() {
    if (this.state.editing) return;
    if (process.env.REACT_APP_DEPLOY_MODE !== 'admin') return;
    this.setState({ editing: true });
  }
  async save() {
    this.setState({ saving: true, error: null });
    const path = `job/${this.props.jobId}/extra-information/`;
    try {
      await postJson({path, body: {data: this.state.draft}});
      this.props.onSave({extraInformation: this.state.draft});
      this.setState({ editing: false });
    } catch {
      this.setState({ error: 'Failed to save description.' });
    }
    this.setState({saving: false});
  }
  render = () => <>
    {
      this.state.error && <div className="alert alert-danger">
        {this.state.error}
      </div>
    }
    <div
      className={`${this.isEditable() && 'cursor-pointer'}`}
      onClick={() => this.edit()} style={{minHeight: 500}}
    >
      {
        this.state.editing ? <>
          <div className="d-flex mb-2">
            <textarea
              id="extra-information"
              placeholder="Edit Description here (Markdown supported)."
              className="form-control code w-50 me-2"
              style={{minHeight: 500}} value={this.state.draft || ''}
              onChange={event => this.setState({draft: event.target.value})}
            />
            <div
              className="w-50 ms-2 border rounded p-2"
              dangerouslySetInnerHTML={
                {__html: this.converter.makeHtml(this.state.draft)}
              }
            />
          </div>
          <div>
            <Submit
              loading={this.state.saving} disabled={this.state.saving}
              setLoading={() => this.setState({saving: true})}
              onClick={() => this.save()}
              label="Save"
            />
            <button
              disabled={this.state.saving}
              className="btn btn-secondary mt-3 ms-2"
              onClick={() => this.setState({editing: false})}
            >Cancel</button>
          </div>
        </> : this.props.data ? <div
          dangerouslySetInnerHTML={
            {__html: this.converter.makeHtml(this.props.data)}
          }
        /> : <em className="text-muted">
          No description yet. Click here to edit.
          {
            this.isEditable() && <span className="clickable-v1_2 ms-2">
              <PencilIcon size={20} />
            </span>
          }
        </em>
      }
    </div>
  </>
}

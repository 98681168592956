import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = Component => props => <Component
  {...props}
  routerFeatures={
    {location: useLocation(), params: useParams(), navigate: useNavigate()}
  }
/>;

export const BootstrapNavLink = ({to, label, routerFeatures: {location}}) => <Link
  className={`nav-link ${location.pathname === to ? 'active' : ''}`}
  to={to}
>{label}</Link>;

import { BACKENDURL } from "../api";

export const TopDomains = ({topDomains}) => topDomains.length > 0 && <div style={{maxWidth: 500}}>
  <h4 className="mb-2">Top Domains</h4>
  <table className="table table-borderless me-5 fs-5">
    <thead>
      <tr>
        <th>Score</th>
        <th>Domain</th>
      </tr>
    </thead>
    <tbody>
      {
        topDomains.map(
          domainEntry => <tr
            key={domainEntry.domain}
          >
            <td>{Number(domainEntry.score.toFixed(3))}</td>
            <td className="overflow-hidden text-truncate">
              <nobr>
                <span className="d-inline-block me-2" style={{width: 30}}>
                  <img
                    src={`${BACKENDURL}/favicon/${domainEntry.domain}/`}
                    alt="" width={23} onError={e => e.target.src = ''}
                  />
                </span>
                <a
                  href={`http://${domainEntry.domain}`}
                  target="_blank" rel="noreferrer"
                  title={domainEntry.domain}
                >{domainEntry.domain}</a>
              </nobr>
            </td>
          </tr>
        )
      }
    </tbody>
  </table>
</div>;

export const Loadable = ({label, additionalDetails, loading, children}) => (
  loading > 0 ? <>
    <div className="d-flex mx-3">
      <h4>Loading {label}...</h4>
      <div className="spinner-border ms-3"></div>
    </div>
    {
      additionalDetails && <div className="d-flex mx-3">
        {additionalDetails}
      </div>
    }
  </> : children
);

import { Component } from "react";
import { postJson } from "../api";
import { Checkbox, Submit } from "./Form";
import { CheckIcon } from "./Icons";

export class JobAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailToNotify: this.props.jobMeta?.emailToNotify || "",
      canRegularUserDownloadResults: this.props.jobMeta?.canRegularUserDownloadResults,
      saving: 0
    };
    this.save = this.save.bind(this);
  }
  async save() {
    const path = `job/${this.props.jobId}/meta/`;
    const body = {
      canRegularUserDownloadResults: this.state.canRegularUserDownloadResults,
      emailToNotify: this.state.emailToNotify,
    };
    await postJson({path, body});
    this.props.onSave(body);
  }
  render = () => <div>
    <h4>Results Access</h4>
    <section>
      <div className="d-flex my-3">
        <div className="form-floating flex-fill">
          <input
            id="email" type="email" className="form-control"
            placeholder="E-mail address to notify"
            value={this.state.emailToNotify}
            onChange={e => this.setState({emailToNotify: e.target.value})}
            disabled={this.props.jobMeta.emailNotificationSent}
          />
          <label htmlFor="email">E-mail address to notify</label>
        </div>
        {
          this.props.jobMeta.emailNotificationSent &&
          <div className="my-auto ms-3 text-success">Sent <CheckIcon /></div>
        }
      </div>
      <Checkbox
        label="Regular User Can Download Results"
        value={this.state.canRegularUserDownloadResults}
        onValueChange={
          value => this.setState({canRegularUserDownloadResults: value})
        }
        disabled={this.state.saving}
      />
    </section>
    <Submit
      label="Save"
      onClick={this.save}
      setLoading={
        () => this.setState(previousState => ({saving: previousState.saving + 1}))
      }
      unsetLoading={
        () => this.setState(previousState => ({saving: previousState.saving - 1}))
      }
      loading={this.state.saving}
    />
  </div>
}

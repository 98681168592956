import { Component } from "react";
import { BACKENDURL, getJson, postJson } from "../api";
import { FlashMessage } from "./FlashMessage";
import { DownloadIcon } from "./Icons";
import { Loadable } from "./Loadable";
import { PredictorTable } from "./PredictorTable";
import { SupervisorSection } from "./SupervisorSection";


export class PredictorsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predictors: null,
      loading: 0, saving: 0, errorMessage: null,
      rebuildQuickModel: true, extractQuickOutput: false,
      buildCompleteModel: false, extractCompleteOutput: false,
    };
    this.excludePredictor = this.excludePredictor.bind(this);
    this.save = this.save.bind(this);
  }
  async componentDidMount() {
    this.setState(previousState => ({loading: previousState.loading + 1}));
    const path = `job/${this.props.jobId}/quickmodel/predictors/json/`;
    const predictors = await getJson({path, asAdmin: false});
    this.setState(
      previousState => ({predictors, loading: previousState.loading - 1})
    );
  }
  excludePredictor(changedRow) {
    this.setState(
      previousState => {
        const newExcluded = !changedRow.excluded;
        previousState.predictors.filter(
          predictor => changedRow.value === predictor.value
        ).forEach(predictor => predictor.excluded = newExcluded);
        return previousState;
      }
    );
  }
  async save({startSelectedStages}) {
    this.setState({errorMessage: null});
    const path = `job/${this.props.jobId}/supervise/`;
    const excludedRows = this.state.predictors.filter(row => row.excluded);
    const body = {
      predictorsToExclude: excludedRows.map(row => row.value),
      rebuildQuickModel: startSelectedStages && this.state.rebuildQuickModel,
      extractQuickOutput: startSelectedStages && this.state.extractQuickOutput,
      validateQuickModel: startSelectedStages && this.state.buildCompleteModel,
      extractCompleteOutput: startSelectedStages && this.state.extractCompleteOutput,
    };
    try {
      await postJson({path, body});
    } catch {
      this.setState({errorMessage: 'Server error occurred.'});
      return;
    }
    if (startSelectedStages) {
      const successMessage = `Predictors excluded and further stages started for job ${this.props.filename}.`
      this.props.navigate('/', {state: {successMessage}})
      return;
    }
    const to = `/job/${this.props.jobId}`;
    this.props.navigate(to, {state: {successMessage: 'Predictors excluded.'}});
  }
  render = () => <>
    <FlashMessage children={this.state.errorMessage} type="danger" />
    <Loadable
      loading={this.state.loading} label="Predictors"
      additionalDetails="It may take up to a few minutes..."
    >
      <PredictorTable
        title='Positive'
        loading={this.state.saving}
        onExcludePredictor={this.excludePredictor}
        predictors={this.state.predictors?.filter(predictor => predictor.multiplier === 'multiplied')}
      />
      <PredictorTable
        title='Negative'
        loading={this.state.saving}
        onExcludePredictor={this.excludePredictor}
        predictors={this.state.predictors?.filter(predictor => predictor.multiplier === 'divided')}
      />
      <div className="d-flex justify-content-between">
        <div>
          {
            process.env.REACT_APP_DEPLOY_MODE === 'admin' && <SupervisorSection
              predictors={this.state.predictors}
              rebuildQuickModel={this.state.rebuildQuickModel}
              extractQuickOutput={this.state.extractQuickOutput}
              buildCompleteModel={this.state.buildCompleteModel}
              extractCompleteOutput={this.state.extractCompleteOutput}
              onSelectionChange={(key, value) => this.setState({[key]: value})}
              onSave={() => this.save({startSelectedStages: false})}
              onSaveAndStart={() => this.save({startSelectedStages: true})}
              onCancel={() => this.props.navigate('/')}
              saving={this.state.saving}
              onLoadingChange={loading => this.setState({saving: loading})}
            />
          }
        </div>
        <div className="mt-auto mb-2">
          <a
            className="btn btn-danger"
            href={`${BACKENDURL}/job/${this.props.jobId}/quickmodel/predictors/file/`}
            rel="noreferrer"
          >Download as CSV <DownloadIcon /></a>
        </div>
      </div>
    </Loadable>
  </>
}
import { Popover } from "bootstrap";
import { Component } from "react";
import { Converter } from "showdown";
import { BACKENDURL, getJson } from "../api";
import { uuid } from "../uuid";
import { HelpIcon } from "./Icons";
import { Loadable } from "./Loadable";

class StatisticsDefinition extends Component {
  constructor(props) {
    super(props);
    this.converter = new Converter({tables: true});
    this.uuid = uuid();
  }
  componentDidMount() {
    const content = this.converter.makeHtml(
      this.props.definition || ''
    ).replace(
      '<table>',
      '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-statistics-${this.uuid}`),
      {trigger: 'hover focus', title: this.props.title, content, html: true}
    );
  }
  render = () => <span id={`popover-statistics-${this.uuid}`} className="link me-2">
    <HelpIcon />
  </span>
}

const ConfusionMatrix = ({flattenedMatrix, definition}) => <section>
  <h4 className="text-nowrap">
    <StatisticsDefinition
      title="Confusion Matrix"
      definition={definition}
    />
    Confusion Matrix
  </h4>
  <table className="table table-sm table-bordered">
    <tbody>
      <tr>
        <td colSpan={2} rowSpan={2} />
        <td className="table-secondary text-center" colSpan={3}>Reference</td>
      </tr>
      <tr className="text-end table-secondary">
        <td className="font-monospace text-danger table-active fw-bold px-2">
          FALSE
        </td>
        <td className="font-monospace text-success table-active fw-bold px-2">
          TRUE
        </td>
      </tr>
      <tr className="table-row-prediction">
        <td className="table-secondary rotate" rowSpan={2}>
          <div>Prediction</div>
        </td>
        <td className="table-danger table-active fw-bold px-2 font-monospace text-end align-middle">
          FALSE
        </td>
        <td className="table-danger text-danger fw-bold px-2 font-monospace text-end align-middle">
          {flattenedMatrix[0]}
        </td>
        <td className="table-danger text-success fw-bold px-2 font-monospace text-end align-middle">
          {flattenedMatrix[2]}
        </td>
      </tr>
      <tr className="font-monospace text-end align-middle table-row-prediction">
        <td className="table-success table-active fw-bold px-2">TRUE</td>
        <td className="table-success text-danger fw-bold px-2">
          {flattenedMatrix[1]}
        </td>
        <td className="table-success text-success fw-bold px-2">
          {flattenedMatrix[3]}
        </td>
      </tr>
    </tbody>
  </table>
</section>;

export const PercentageProgressBar = ({value, color, height}) => <div
  className="progress bg-secondary position-relative"
  style={{height: Number.isInteger(height) ? height : 20, fontSize: 14}}
>
  <div
    className={`progress-bar bg-${color || 'danger'} fw-bold overflow-visible px-2`}
    style={{width: `${value * 100}%`}}
  >{Number((value * 100).toFixed(2))}%</div>
</div>;

function colorByKappa(value) {
  if (value > 0.9) { return 'success'; }
  if (value > 0.8) { return 'primary'; }
  if (value > 0.6) { return 'warning'; }
  return 'danger';
}

class KappaRow extends Component {
  label() {
    if (this.props.value > 0.9) { return 'ALMOST PERFECT'; }
    if (this.props.value > 0.8) { return 'STRONG'; }
    if (this.props.value > 0.7) { return 'MODERATE'; }
    if (this.props.value > 0.6) { return 'WEAK'; }
    if (this.props.value >= 0.5) { return 'MINIMAL'; }
    return 'Poor';
  }
  render = () => <tr className={`table-${colorByKappa(this.props.value)} fs-5`}>
    <td className="fw-bold ps-3">
      <StatisticsDefinition
        title="Cohen's Kappa"
        definition={this.props.definition}
      />
      κ
    </td>
    <td className="text-muted fw-bold ps-2">
      (<em>Kappa</em> — Model Quality)
    </td>
    <td className="text-end ps-3 pe-3">
      <span className={`badge bg-${colorByKappa(this.props.value)} me-2`}>
        {this.label()}
      </span>
      <span className="font-monospace">
        {Number(this.props.value.toFixed(4))}
      </span>
    </td>
  </tr>
}

class OverallStatistics extends Component {
  formatValue(key) {
    const result = this.props.values[key];
    if (key === 'AccuracyPValue' && result <= Number.EPSILON) {
      return '< ε';
    }
    return Number(result.toFixed(4));
  }
  render = () => <section>
    <h4>Overall</h4>
    <table className="table table-sm">
      <tbody>
        <KappaRow
          value={this.props.values.Kappa}
          definition={this.props.definitions.kappa}
        />
        <tr>
          <td colSpan={2} className="fw-bold ps-3">
            <StatisticsDefinition
              title="Balanced Accuracy"
              definition={this.props.definitions.accuracy}
            />
            Balanced Accuracy
          </td>
          <td className="text-end ps-3 pe-3">
            <PercentageProgressBar
              value={this.props.values.Accuracy}
              color={colorByKappa(this.props.values.Kappa)}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="fw-bold ps-3">
            <StatisticsDefinition
              title="95% Confidence Interval"
              definition={this.props.definitions.confidenceInterval}
            />
            95% Confidence Interval
          </td>
          <td className="font-monospace text-end ps-3 pe-3">
            {this.formatValue('AccuracyLower')}
            <span className="mx-2">
              &lt;
              <span className="badge bg-light text-dark border border-dark mx-2">
                95% CI
              </span>
              &lt;
            </span>
            {this.formatValue('AccuracyUpper')}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="fw-bold ps-3">
            <StatisticsDefinition
              title="No Information Rate"
              definition={this.props.definitions.noInformationRate}
            />
            No Information Rate
          </td>
          <td className="font-monospace text-end ps-3 pe-3">
            {this.formatValue('AccuracyNull')}
          </td>
        </tr>
        <tr>
          <td className="fw-bold ps-3">
            <StatisticsDefinition
              title="<em>p</em>-value"
              definition={this.props.definitions.pValue}
            />
            <em>p</em>-value
          </td>
          <td className="text-muted fw-bold ps-2">
            (<em>Accuracy</em> Higher Than <em>No Information Rate</em>)
          </td>
          <td className="font-monospace text-end ps-3 pe-3">
            {this.formatValue('AccuracyPValue')}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
}

class OtherParameters extends Component {
  label(key) {
    if (key === 'Pos Pred Value') { return 'Positive Prediction Rate'; }
    if (key === 'Neg Pred Value') { return 'Negative Prediction Rate'; }
    return key;
  }
  render = () => <section>
    <h4>Other Parameters</h4>
    <table className="table table-sm table-striped">
      <tbody>
        {
          [
            ['sensitivity', 'Sensitivity'],
            ['specificity', 'Specificity'],
            ['positivePredictionRate', 'Pos Pred Value'],
            ['negativePredictionRate', 'Neg Pred Value'],
            ['prevalence', 'Prevalence'],
            ['detectionRate', 'Detection Rate'],
            ['detectionPrevalence', 'Detection Prevalence']
          ].map(
            ([definitionKey, statisticsKey]) => <tr key={statisticsKey}>
              <td className="fw-bold" style={{width: 250}}>
                <StatisticsDefinition
                  title={this.label(statisticsKey)}
                  definition={this.props.definitions[definitionKey]}
                />
                {this.label(statisticsKey)}
              </td>
              <td className="text-end">
                <PercentageProgressBar value={this.props.values[statisticsKey]} />
              </td>
            </tr>
          )
        }
      </tbody>
    </table>
  </section>
}

class Sankey extends Component {
  async componentDidMount() {
    const data = this.props.data;
    // eslint-disable-next-line no-undef
    google.charts.load('current', {'packages':['sankey']});
    // eslint-disable-next-line no-undef
    google.charts.setOnLoadCallback(drawChart);
    function drawChart() {
      // eslint-disable-next-line no-undef
      var dataTable = new google.visualization.DataTable();
      dataTable.addColumn('string', 'From');
      dataTable.addColumn('string', 'To');
      dataTable.addColumn('number', 'Weight');
      dataTable.addRows(
        data.links.map(link => [link.source, link.target, link.value])
      );
      // eslint-disable-next-line no-undef
      const chart = new google.visualization.Sankey(window.sankeyDiagram);
      const colors = data.links.reduce(
        (accumulator, current) => [
          ...accumulator,
          ...(accumulator.includes(current.source) ? [] : [current.source]),
          ...(accumulator.includes(current.target) ? [] : [current.target]),
        ], []
      ).map(nodeName => data.colors[nodeName]);
      const nodeOptions = {colors, label: {fontSize: 16}};
      const options = {
        sankey: {
          node: nodeOptions,
          link: {colorMode: 'gradient'},
          iterations: 0
        },
      };
      chart.draw(dataTable, options);
    }
  }
  render = () => <div
    id="sankeyDiagram" className="flex-fill sankey-diagram"
    style={{height: 500, width: 520}}
  />
}

export class QuickModelStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: null, sankey: null, showSankeyAsImage: false,
      statisticsLoading: 1, sankeyLoading: 1
    };
  }
  async fetchStatistics() {
    const path = `job/${this.props.jobId}/statistics/json/`;
    const statistics = await getJson({path, asAdmin: false});
    this.setState(
      {statistics}, () => this.setState(
        previousState => (
          {statisticsLoading: previousState.statisticsLoading - 1}
        )
      )
    );
  }
  async fetchSankey() {
    const path = `job/${this.props.jobId}/statistics/sankey/data/`;
    try {
      const sankey = await getJson({path, asAdmin: false});
      if (!sankey) {
        this.setState(
          {showSankeyAsImage: true}, () => this.setState(
            previousState => ({sankeyLoading: previousState.sankeyLoading - 1})
          )
        );
        return;
      }
      this.setState(
        {sankey}, () => this.setState(
          previousState => ({sankeyLoading: previousState.sankeyLoading - 1})
        )
      );
    } catch {
      this.setState(
        {showSankeyAsImage: true}, () => this.setState(
          previousState => ({sankeyLoading: previousState.sankeyLoading - 1})
        )
      );
    }
  }
  componentDidMount() {
    this.fetchStatistics();
    this.fetchSankey();
  }
  sankeyUrl() {
    return `${BACKENDURL}/job/${this.props.jobId}/statistics/sankey/image/`;
  }
  render = () => <div className="d-flex">
    <div style={{width: 1000}} className="pe-5">
      <Loadable label="Statistics" loading={this.state.statisticsLoading}>
        {
          this.state.statistics && <>
            <section className="d-flex">
              <div className="me-5">
                <ConfusionMatrix
                  flattenedMatrix={this.state.statistics.table}
                  definition={this.state.statistics.definitions.confusionMatrix}
                />
              </div>
              <div className="ms-1">
                <OverallStatistics
                  values={this.state.statistics.overall}
                  definitions={this.state.statistics.definitions}
                />
              </div>
            </section>
            <OtherParameters
              values={this.state.statistics.byClass}
              definitions={this.state.statistics.definitions}
            />
          </>
        }
      </Loadable>
    </div>
    <div className="flex-fill w-100">
      <Loadable label="Sankey Diagram" loading={this.state.sankeyLoading}>
        <h4>Sankey Diagram</h4>
        {
          this.state.showSankeyAsImage ? <div
            style={
              {
                backgroundImage: `url(${this.sankeyUrl()})`,
                backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                width: 620, height: 620
              }
            }
          /> : (this.state.sankey && <Sankey data={this.state.sankey} />)
        }
      </Loadable>
    </div>
  </div>
}

import { Component } from "react";
import { BACKENDURL, getJson } from "../api";
import { Loadable } from "./Loadable";
import { numberFormat } from 'humanize';
import { DownloadIcon } from "./Icons";
import { ResultFrequencyChart } from "./ResultFrequencyChart";
import { areResultsDownloadable } from "../results";
import { TopDomains } from "./TopDomains";

class ResultSelectionSummary extends Component {
  selectedEntriesToDownloadCount() {
    return this.props.intervals.filter(
      entry => entry.leftScore >= -this.props.intervalCutOffScore
    ).reduce((acc, {value}) => acc + value, 0);
  }
  render = () => <div className="fs-5">
    Selected
    <strong className="mx-2">
      {numberFormat(this.selectedEntriesToDownloadCount(), 0)} entries
    </strong>
    with
    <strong className="ms-2">
      score &gt; {-this.props.intervalCutOffScore}
    </strong>.
  </div>
}

class ResultSelectionDownload extends Component {
  url() {
    const jobUrl = `${BACKENDURL}/job/${this.props.jobId}`;
    const cutOff = -this.props.intervalCutOffScore;
    if (process.env.REACT_APP_DEPLOY_MODE !== 'admin') {
      return `${jobUrl}/completeoutput/results/with-score-higher-than/${cutOff}/file`;
    }
    return `${jobUrl}/completeoutput/results/with-score-higher-than/${cutOff}/file/admin`;
  }
  render = () => <a className="btn btn-danger btn ms-2" href={this.url()}>
    Download Selected <DownloadIcon />
  </a>
}

class ResultTopDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {isOpen: false};
  }
  url(cutOff) {
    const jobUrl = `${BACKENDURL}/job/${this.props.jobId}`;
    if (process.env.REACT_APP_DEPLOY_MODE !== 'admin') {
      return `${jobUrl}/completeoutput/results/with-score-higher-than/${cutOff}/file`;
    }
    return `${jobUrl}/completeoutput/results/with-score-higher-than/${cutOff}/file/admin`;
  }
  render = () => <div className="dropdown">
    <button
      className="btn btn-outline-danger dropdown-toggle"
      data-bs-toggle="dropdown"
      onClick={() => this.setState((previousState) => ({isOpen: !previousState.isOpen}))}
    >
      Download Top
    </button>
    <ul className={`dropdown-menu ${this.state.isOpen ? 'show' : ''}`}>
      {
        [
          ...new Set(
            [0.95, 0.9, 0.8, Number(this.props.threshold.toFixed(3))]
          )
        ].sort().reverse().map(
          cutOff => <li key={`download-top-${cutOff}`}>
            <a className="dropdown-item" href={this.url(cutOff)}>
              Score &gt; {cutOff}
              {Number(this.props.threshold.toFixed(3)) === cutOff && " (threshold)"}
            </a>
          </li>
        )
      }
    </ul>
  </div>
}

export class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {intervals: null, loading: 1, intervalCutOffScore: -0.95};
  }
  async componentDidMount() {
    const path = `job/${this.props.jobId}/results/intervals/json/`;
    const intervals = await getJson({path, asAdmin: false});
    this.setState(
      {
        intervals,
        intervalCutOffScore: -(intervals?.threshold || 0) || this.state.intervalCutOffScore
      },
      () => this.setState(
        previousState => ({loading: previousState.loading - 1})
      )
    );
  }
  render = () => <Loadable label="Results Statistics" loading={this.state.loading}>
    {
      this.state.intervals && <>
        <div className="d-flex">
          <TopDomains topDomains={this.state.intervals.topDomains} />
          <div className="flex-fill">
            <h4>Interactive Frequency Chart</h4>
            <div className="d-flex mb-3">
              <div className="flex-fill">
                <ResultSelectionSummary
                  intervals={this.state.intervals?.frequenciesInIntervals}
                  intervalCutOffScore={this.state.intervalCutOffScore}
                />
              </div>
              {
                areResultsDownloadable(this.props.jobMeta) && <>
                  <ResultTopDownload
                    jobId={this.props.jobId}
                    threshold={this.state.intervals.threshold}
                  />
                  <ResultSelectionDownload
                    jobId={this.props.jobId}
                    intervalCutOffScore={this.state.intervalCutOffScore}
                  />
                </>
              }
            </div>
            <ResultFrequencyChart
              data={this.state.intervals}
              cutOffScore={this.state.intervalCutOffScore}
              onSelectionChange={
                intervalCutOffScore => this.setState({intervalCutOffScore})
              }
              className="flex-fill"
            />
          </div>
        </div>
      </>
    }
  </Loadable>
}

import { Component } from "react";
import { BACKENDURL, getJson } from "../api";
import { Loadable } from "./Loadable";

function colorByKappa(value) {
  if (value > 0.9) { return 'success'; }
  if (value > 0.8) { return 'primary'; }
  if (value > 0.6) { return 'warning'; }
  return 'danger';
}

function standardDeviation(values) {
  if (values.length < 2) {
    throw new Error(
      'Wymagane są co najmniej dwie wartości do obliczenia odchylenia standardowego.'
    );
  }
  const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
  const sumOfSquares = values.reduce(
    (sum, value) => sum + Math.pow(value - mean, 2), 0
  );
  return Math.sqrt(sumOfSquares / (values.length - 1));
}

export class NFoldStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nFoldStatistics: [...Array(10).keys()].map(() => ({statisticsLoading: 1}))
    };
  }
  async fetchStatistics() {
    await Promise.all(
      [...Array(10).keys()].map(
        async index => {
          const path = `job/${this.props.jobId}/statistics/${index+1}-fold/json/`;
          const statistics = await getJson({ path, asAdmin: false });
          this.setState(
            previousState => {
              const nFoldStatistics = [...previousState.nFoldStatistics];
              nFoldStatistics[index] = {
                statistics,
                statisticsLoading: nFoldStatistics.statisticsLoading - 1
              };
              return { nFoldStatistics };
            }
          );
        }
      )
    );
  }
  componentDidMount() {
    this.fetchStatistics();
  }
  render = () => <Loadable
    loading={
      this.state.nFoldStatistics.some(({statisticsLoading}) => statisticsLoading > 0)
    }
  >
    <table className="table table-hover">
      <thead>
        <tr>
          <th><em>n</em>-fold</th>
          <th>Kappa</th>
          <th>Accuracy</th>
          <th>Sensitivity</th>
          <th>Specificity</th>
          <th>Precision</th>
          <th>Negative Prediction Rate</th>
        </tr>
      </thead>
      <tbody className="table-group-divider">
        <tr>
          <td><strong>Mean</strong></td>
          <td
            className={
              `
                table-${
                  colorByKappa(
                    this.state.nFoldStatistics.map(
                      ({statistics}) => statistics?.overall.kappa
                    ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                  )
                }
              `
            }
          >
            <strong>
              {
                (
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.overall.kappa
                  ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                ).toFixed(3)
              }
            </strong>
          </td>
          <td
            className={
              `
                table-${
                  colorByKappa(
                    this.state.nFoldStatistics.map(
                      ({statistics}) => statistics?.overall.kappa
                    ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                  )
                }
              `
            }
          >
            <strong>
              {
                (
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.overall.accuracy
                  ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                (
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.sensitivity
                  ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                (
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.specificity
                  ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                (
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.precision
                  ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                (
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.negativePredictionRate
                  ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                ).toFixed(3)
              }
            </strong>
          </td>
        </tr>
        <tr>
          <td><strong>Std. dev.</strong></td>
          <td
            className={
              `
                table-${
                  colorByKappa(
                    this.state.nFoldStatistics.map(
                      ({statistics}) => statistics?.overall.kappa
                    ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                  )
                }
              `
            }
          >
            <strong>
              {
                standardDeviation(
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.overall.kappa
                  )
                ).toFixed(3)
              }
            </strong>
          </td>
          <td
            className={
              `
                table-${
                  colorByKappa(
                    this.state.nFoldStatistics.map(
                      ({statistics}) => statistics?.overall.kappa
                    ).reduce((a, b) => a+b, 0) / this.state.nFoldStatistics.length
                  )
                }
              `
            }
          >
            <strong>
              {
                standardDeviation(
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.overall.accuracy
                  )
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                standardDeviation(
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.sensitivity
                  )
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                standardDeviation(
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.specificity
                  )
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                standardDeviation(
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.precision
                  )
                ).toFixed(3)
              }
            </strong>
          </td>
          <td>
            <strong>
              {
                standardDeviation(
                  this.state.nFoldStatistics.map(
                    ({statistics}) => statistics?.byClass.negativePredictionRate
                  )
                ).toFixed(3)
              }
            </strong>
          </td>
        </tr>
        {
          this.state.nFoldStatistics.map(
            ({statistics}, index) => <tr key={index}>
              <td><strong>{index+1}</strong></td>
              <td
                className={
                  `table-${colorByKappa(statistics?.overall.kappa.toFixed(3))}`
                }
              >{statistics?.overall.kappa.toFixed(3)}</td>
              <td
                className={
                  `table-${colorByKappa(statistics?.overall.kappa.toFixed(3))}`
                }
              >{statistics?.overall.accuracy.toFixed(3)}</td>
              <td>{statistics?.byClass.sensitivity.toFixed(3)}</td>
              <td>{statistics?.byClass.specificity.toFixed(3)}</td>
              <td>{statistics?.byClass.precision.toFixed(3)}</td>
              <td>{statistics?.byClass.negativePredictionRate.toFixed(3)}</td>
            </tr>
          )
        }
      </tbody>
    </table>
  </Loadable>
}

export const ScatterPlot = ({jobId}) => <img
  src={`${BACKENDURL}/job/${jobId}/statistics/n-fold/scatter-plot/image/`}
  alt="Scatter plot"
  style={{maxHeight: 600}}
/>;

import { Component } from 'react';
import { Modal as BModal } from 'bootstrap';
import { uuid } from '../uuid';
import { deleteJson, getJson } from '../api';
import { Submit } from './Form';
import ApexCharts from "apexcharts";
import { CheckIcon } from './Icons';

class Modal extends Component {
  uuid = uuid()
  modal = null
  componentDidMount = () => this.modal = new BModal(document.getElementById(this.uuid))
  componentDidUpdate = () => this.props.show ? this.modal.show() : this.modal.hide()
  render = () => <div
    id={this.uuid} className="modal fade" data-bs-backdrop="static"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body">{this.props.children}</div>
      </div>
    </div>
  </div>
}

export class DeleteJobModals extends Component {
  state = {error: null, loading: 0, succeeded: false}
  async submit() {
    try {
      await deleteJson({path: `job/${this.props.job.id}/`});
      this.setState({succeeded: true});
    } catch {
      this.setState({error: 'Failed to delete the job.'});
    }
  }
  resetState = () => this.setState({error: null, succeeded: false}) && this.props.onCancel()
  render = () => <>
    <Modal show={this.state.error}>
      <h3>Job Deletion Error</h3>
      <p>{this.state.error}</p>
      <button className="btn btn-danger" onClick={() => this.resetState()}>
        OK
      </button>
    </Modal>
    <Modal show={this.state.succeeded}>
      <h3>Job Deletion Success</h3>
      <p>Job deleted successfully!</p>
      <button className="btn btn-danger" onClick={() => window.location.reload()}>
        OK
      </button>
    </Modal>
    <Modal show={this.props.job && !this.state.error && !this.state.succeeded}>
      <h3>Delete Job</h3>
      <p>
        Are you sure you want to delete&nbsp;
        <em><strong>{this.props.job?.filename}</strong></em> job?
      </p>
      <Submit
        label="Delete"
        onClick={() => this.submit()} loading={this.state.loading}
        setLoading={() => this.setState(previousState => ({loading: previousState.loading + 1}))}
        unsetLoading={() => this.setState(previousState => ({loading: previousState.loading - 1}))}
      />
      <button
        data-bs-dismiss="modal"
        className="btn btn-secondary mt-3 ms-2"
        disabled={this.state.loading > 0} onClick={() => this.resetState()}
      >Cancel</button>
    </Modal>
  </>;
}

export class JobStartupStatusModal extends Component {
  animations = {update: 200, speed: 50}
  state = {
    finished: false, close: false, progress: 0, fakeDbiAnalysisProgress: 0,
    isQuickModelBuilt: false, isQuickOutputBuilt: false
  }
  constructor(props) {
    super(props);
    this.chartId = `predictors-live-chart-${uuid()}`;
    this.chart = null;
  }
  series = ({weight}) => weight
  categories = ({attribute, value}) => `${attribute}: ${value}`
  options = () => (
    {
      series: [{data: Array(10).fill(0)}],
      xaxis: {
        categories: Array(10).fill(''),
        labels: {style: {color: '#000', fontWeight: 1000}}
      },
      plotOptions: {bar: {dataLabels: {position: 'top'}}},
      dataLabels: {offsetY: -20, style: {colors: ['#000']}},
      chart: {
        toolbar: {show: false},
        type: 'bar', height: 350,
        animations: {
          enable: false, easing: 'linear',
          speed: this.animations.speed,
          animateGradually: {enabled: false, delay: 0},
          dynamicAnimation: {
            enabled: true, speed: this.animations.speed
          },
        }
      },
      colors: Array(10).fill('#d12239'),
      yaxis: {
        max: this.props.comparedStatistics.map(
          x => x.reduce((a, b) => Math.max(a, b.weight), 0)
        ).reduce((a, b) => Math.max(a, b), 0)
      } 
    }
  )
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.finished) {
      if (prevState.finished) return;
      while (true) {
        const isQuickModelBuilt = await getJson(
          {path: `job/${this.props.jobId}/quick-model/is-built/`, asAdmin: true}
        );
        if (isQuickModelBuilt) {
          this.setState({isQuickModelBuilt});
          break;
        }
      }
      while (true) {
        const isQuickOutputBuilt = await getJson(
          {path: `job/${this.props.jobId}/quick-output/is-built/`, asAdmin: true}
        );
        if (isQuickOutputBuilt) {
          this.setState({isQuickOutputBuilt: true});
          break;
        }
      }
      return;
    }
    if (prevProps.analysisStarted !== this.props.analysisStarted) {
      for (let i = 0; i < 50; i++) {
        this.setState({fakeDbiAnalysisProgress: i});
        await new Promise(resolve => setTimeout(resolve, this.animations.update * 0.7));
      }
      for (let i = 0; i < 50; i++) {
        this.setState(
          {fakeDbiAnalysisProgress: Math.atan(50 + i) / (Math.PI / 2) * 75}
        );
        await new Promise(resolve => setTimeout(resolve, this.animations.update));
      }
      return;
    }
    if (prevProps.comparedStatistics === this.props.comparedStatistics) return;
    let data = this.props.comparedStatistics;
    if (!data || data.length === 0) return;
    data = [data[0].map(entry => ({...entry, weight: 0})), ...data];
    if (!this.chart) {
      const chartElement = document.getElementById(this.chartId);
      this.chart = new ApexCharts(chartElement, this.options());
      await this.chart.render();
    }
    const resolution = 100 / (data.length - 1);
    for (let i = 0; i < data.length - 1; i++) {
      const current = data[i];
      const next = data[i + 1];
      for (let j = 0; j < resolution; j++) {
        this.setState({progress: i * resolution + j});
        const currentSorted = current.map(
          (element, index) => (
            {
              ...element, weight: element.weight + (
                parseInt((next[index].weight - element.weight) * j / resolution)
              )
            }
          )
        ).sort((a, b) => b.weight - a.weight);
        this.chart.updateOptions(
          {
            series: [{data: currentSorted.map(this.series)}],
            xaxis: {categories: currentSorted.map(this.categories)},
          }
        );
        await new Promise(resolve => setTimeout(resolve, this.animations.updateSpeed));
      }
    }
    this.setState({finished: true});
  }
  render = () => <Modal show={this.props.submitted && !this.state.close}>
    <div className="my-4 mx-5">
      {
        this.props.jobId ? <>
          <h3>
            Job Started&nbsp;
            <span className="text-success ms-2">
              <CheckIcon size={24} />
            </span>
          </h3>
          {
            this.props.analysisStarted ? <>
              <h3>
                DBI Data Joined&nbsp;
                <span className="text-success ms-2">
                  <CheckIcon size={24} />
                </span>
              </h3>
              {
                (this.props.comparedStatistics && this.props.comparedStatistics.length > 0) ? <>
                  <h3>
                    DBI Attributes Analyzed&nbsp;
                    <span className="text-success ms-2">
                      <CheckIcon size={24} />
                    </span>
                  </h3>
                  {
                    this.state.finished ? <h3>
                      The Best Predictors Found In DBI Attributes&nbsp;
                      <span className="text-success ms-2">
                        <CheckIcon size={24} />
                      </span>
                    </h3> : <>
                      <h3>
                        Finding The Best Predictors From DBI Attributes...&nbsp;
                        <span className="progress mt-2">
                          <div
                            className="progress-bar bg-danger"
                            style={{width: `${this.state.progress}%`}}
                          />
                        </span>
                      </h3>
                    </>
                  }
                  <div id={this.chartId} />
                  {
                    this.state.finished ? (
                      this.state.isQuickModelBuilt ? <>
                        <h3>
                          Quick Model Built&nbsp;
                          <span className="text-success ms-2">
                            <CheckIcon size={24} />
                          </span>
                        </h3>
                        {
                          this.state.isQuickOutputBuilt ? <>
                            <h3>
                              Quick Output Built&nbsp;
                              <span className="text-success ms-2">
                                <CheckIcon size={24} />
                              </span>
                            </h3>
                            <div className="d-flex mt-3">
                              <div className="flex-fill" />
                              <a
                                href={`/#/v1.3/job/${this.props.jobId}`}
                                className="btn btn-danger"
                                onClick={() => this.setState({close: true})}
                              >Go to the Job Page</a>
                            </div>
                          </> : <h3>
                            Building Quick Output...&nbsp;
                            <span className="spinner-border ms-2" style={{borderWidth: 5}} />
                          </h3>
                        }
                      </> : <>
                        <h3>
                          Building Quick Model...&nbsp;
                          <span className="spinner-border ms-2" style={{borderWidth: 5}} />
                        </h3>
                        <h3 className="text-muted fst-italic">Build Quick Output</h3>
                      </>
                    ) : <>
                      <h3 className="text-muted fst-italic">Build Quick Model</h3>
                      <h3 className="text-muted fst-italic">Build Quick Output</h3>
                    </>
                  }
                </> : <>
                  <h3>
                    Analyzing DBI Attributes...&nbsp;
                    <div className="progress mt-2">
                      <div
                        className="progress-bar bg-danger"
                        style={{width: `${this.state.fakeDbiAnalysisProgress}%`}}
                      />
                    </div>
                  </h3>
                  <h3 className="text-muted fst-italic">
                    Find The Best Predictors From DBI Attributes
                  </h3>
                  <h3 className="text-muted fst-italic">Build Quick Model</h3>
                  <h3 className="text-muted fst-italic">Build Quick Output</h3>
                </>
              }
            </> : <>
              <h3>
                Joining DBI Data...&nbsp;
                <span className="spinner-border ms-2" style={{borderWidth: 5}} />
              </h3>
              <h3 className="text-muted fst-italic">Analyze DBI Attributes</h3>
              <h3 className="text-muted fst-italic">
                Find The Best Predictors From DBI Attributes
              </h3>
              <h3 className="text-muted fst-italic">Build Quick Model</h3>
              <h3 className="text-muted fst-italic">Build Quick Output</h3>
            </>
          }
        </> : <>
          <h3>
            Starting Job...&nbsp;
            <span className="spinner-border ms-2" style={{borderWidth: 5}} />
          </h3>
          <h3 className="text-muted fst-italic">Join DBI Data</h3>
          <h3 className="text-muted fst-italic">Analyze DBI Attributes</h3>
          <h3 className="text-muted fst-italic">
            Find The Best Predictors From DBI Attributes
          </h3>
          <h3 className="text-muted fst-italic">Build Quick Model</h3>
          <h3 className="text-muted fst-italic">Build Quick Output</h3>
        </>
      }
    </div>
  </Modal>
}

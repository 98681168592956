import { Popover } from "bootstrap";
import { Component } from "react";
import { Converter } from "showdown";

const PredictorHeader = () => <tr>
  <th className="table-col-label">Label</th>
  <th className="table-col-attribute">Attribute</th>
  <th className="table-col-value">Value</th>
  <th className="table-col-avg-input">Avg. in Input</th>
  <th className="table-col-avg-internet">Avg. in the Internet</th>
  <th className="table-col-interpretation">Which is...</th>
</tr>;

function MaybeStroke({stroke, children}) {
  return stroke ? <del className="text-muted">{children}</del> : children;
}

class PredictorRow extends Component {
  constructor(props) {
    super(props);
    this.converter = new Converter({tables: true});
  }
  componentDidMount() {
    const row = this.props.row;
    if (!row?.definition) {
      return;
    }
    const name = row?.attribute;
    const title = row?.label || name;
    const content = this.converter.makeHtml(row?.definition || '').replace(
      '<table>',
      '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-label-${row?.group}-${name}`),
      {trigger: 'hover focus', title, content, html: true}
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-attribute-${row?.group}-${name}`),
      {trigger: 'hover focus', title, content, html: true}
    );
  }
  render = () => <tr>
    <td className="table-col-label">
      <span
        id={`popover-label-${this.props.row?.group}-${this.props.row?.attribute}`}
        className="d-inline-block"
        title={this.props.row?.label}
      >
        <MaybeStroke stroke={this.props.row?.excluded}>
          {this.props.row?.label}
        </MaybeStroke>
      </span>
    </td>
    <td
      className="table-col-attribute"
      id={`popover-attribute-${this.props.row?.group}-${this.props.row?.attribute}`}
      title={this.props.row?.attribute}
    >
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.attribute}
      </MaybeStroke>
    </td>
    <td className="table-col-value">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.value || <em className="text-muted">N/A</em>}
      </MaybeStroke>
    </td>
    <td className="table-col-avg-input">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {
          this.props.row?.value ? (
            `${(this.props.row?.inputAverage * 100).toFixed(2)}%`
          ) : this.props.row?.inputAverage.toFixed(2)
        }
      </MaybeStroke>
    </td>
    <td className="table-col-avg-internet">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {
          this.props.row?.value ? (
            `${(this.props.row?.internetAverage * 100).toFixed(2)}%`
          ) : this.props.row?.internetAverage.toFixed(2)
        }
      </MaybeStroke>
    </td>
    <td
      className="table-col-interpretation"
      title={
        (this.props.row?.inputAverage > this.props.row?.internetAverage) ? (
          `${(this.props.row?.inputAverage / this.props.row?.internetAverage).toFixed(2)} times more ${this.props.row?.value ? 'common' : ''} in the input`
        ) : (
          `${(this.props.row?.internetAverage / this.props.row?.inputAverage).toFixed(2)} times more ${this.props.row?.value ? 'common' : ''} in the Internet`
        )
      }
    >
      <MaybeStroke stroke={this.props.row?.excluded}>
        {
          (this.props.row?.inputAverage > this.props.row?.internetAverage) ? (
            `${(this.props.row?.inputAverage / this.props.row?.internetAverage).toFixed(2)} times more ${this.props.row?.value ? 'common' : ''} in the input`
          ) : (
            `${(this.props.row?.internetAverage / this.props.row?.inputAverage).toFixed(2)} times more ${this.props.row?.value ? 'common' : ''} in the Internet`
          )
        }
      </MaybeStroke>
    </td>
  </tr>
}

export const FeatureTable = ({predictors, loading}) => <section>
  <h4 className="border-bottom border-2">Features</h4>
  <table className="table table-sm table-scrollable collapse show">
    <thead><PredictorHeader /></thead>
    <tbody style={{height: 500}}>
      {
        predictors?.map(
          (row, i) => <PredictorRow
            key={`simplified-${i}-${row?.attribute}`}
            row={row} loading={loading}
          />
        )
      }
    </tbody>
  </table>
</section>;

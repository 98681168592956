import { Component } from "react";
import { uuid } from "../uuid";

export const FileUploader = ({setSelectedFile, disabled}) => <input
  className="form-control mb-2" type="file" disabled={disabled}
  onChange={event => setSelectedFile(event.target.files[0])}
/>;

export function Checkbox({value, onValueChange, label, disabled, title}) {
  const id = `checkbox-${uuid()}`;
  return <div
    className={`form-check form-switch form-check-inline my-0 py-0 ${label || 'me-0'}`}
    title={title}
  >
    <input
      className={`form-check-input bg-${value ? 'danger' : 'light'} border border-danger`}
      type="checkbox"
      checked={value}
      id={id}
      onChange={event => onValueChange(event.target.checked)}
      disabled={disabled}
    />
    {label && <label className="form-check-label" htmlFor={id}>{label}</label>}
  </div>
}

export class Submit extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleSubmit() {
    if (this.props.setLoading) {
      this.props.setLoading();
    }
    await this.props.onClick();
    if (this.props.unsetLoading) {
      this.props.unsetLoading();
    }
  }
  render = () => <button
    className={`btn btn-${this.props.type || 'danger'} mt-3 ${this.props.className}`}
    disabled={this.props.loading || this.props.disabled}
    onClick={this.handleSubmit}
  >
    {this.props.label || 'Submit'}
    <div className={`spinner-border spinner-border-sm ms-2 ${this.props.loading ? '' : 'd-none'}`} />
  </button>
}

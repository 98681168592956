import { Component } from "react";
import { Checkbox, Submit } from "./Form";

const SuperviseStagesSection = (
  {
    anyPredictorExcluded,
    rebuildQuickModel, extractQuickOutput,
    buildCompleteModel, extractCompleteOutput,
    onSelectionChange, saving,
  }
) => <div>
  <Checkbox
    value={anyPredictorExcluded && rebuildQuickModel}
    onValueChange={value => onSelectionChange('rebuildQuickModel', value)}
    label="Rebuild Quick Model"
    title={!anyPredictorExcluded ? 'No predictors excluded, rebuilding will not introduce any change in the model.' : null}
    disabled={!anyPredictorExcluded || saving}
  />
  <Checkbox
    value={rebuildQuickModel && extractQuickOutput}
    onValueChange={value => onSelectionChange('extractQuickOutput', value)}
    label="Extract Quick Output"
    disabled={!rebuildQuickModel || saving}
  />
  <Checkbox
    value={rebuildQuickModel && buildCompleteModel}
    onValueChange={value => onSelectionChange('buildCompleteModel', value)}
    label="Build Complete Model"
    disabled={!rebuildQuickModel || saving}
  />
  <Checkbox
    value={rebuildQuickModel && buildCompleteModel && extractCompleteOutput}
    onValueChange={value => onSelectionChange('extractCompleteOutput', value)}
    label="Extract Complete Output"
    disabled={!rebuildQuickModel || !buildCompleteModel || saving}
  />
</div>;

class SuperviseSubmitSection extends Component {
  constructor(props) {
    super(props);
    this.state = {saving: 0, savingAndStarting: 0};
    this.loading = this.loading.bind(this);
    this.increaseSaving = this.increaseSaving.bind(this);
    this.increaseSavingAndStarting = this.increaseSavingAndStarting.bind(this);
  }
  loading() {
    return this.state.saving + this.state.savingAndStarting;
  }
  increaseSaving(count) {
    this.setState(
      previousState => ({saving: previousState.saving + count}),
      () => this.props.onLoadingChange(this.loading())
    );
  }
  increaseSavingAndStarting(count) {
    this.setState(
      previousState => ({savingAndStarting: previousState.savingAndStarting + count}),
      () => this.props.onLoadingChange(this.loading())
    );
  }
  render = () => <div className="pb-2">
    <Submit
      label="Save and Start Selected Stages"
      onClick={this.props.onSaveAndStart}
      loading={this.state.savingAndStarting} disabled={this.state.saving}
      setLoading={() => this.increaseSavingAndStarting(1)}
      unsetLoading={() => this.increaseSavingAndStarting(-1)}
    />
    <Submit
      label="Save Only" type="outline-danger" className="ms-1"
      onClick={this.props.onSave}
      loading={this.state.saving} disabled={this.state.savingAndStarting}
      setLoading={() => this.increaseSaving(1)}
      unsetLoading={() => this.increaseSaving(-1)}
    />
    <Submit
      label="Cancel and Discard Changes"
      type="outline-secondary" className="ms-1"
      onClick={this.props.onCancel}
      disabled={this.state.saving + this.state.savingAndStarting}
    />
  </div>
}

export const SupervisorSection = (
  {
    predictors,
    rebuildQuickModel, extractQuickOutput,
    buildCompleteModel, extractCompleteOutput,
    onSelectionChange,  onSave, onSaveAndStart, onCancel,
    saving, onLoadingChange,
  }
) => <>
  <SuperviseStagesSection
    anyPredictorExcluded={predictors?.rows?.some(row => row.excluded)}
    rebuildQuickModel={rebuildQuickModel}
    extractQuickOutput={extractQuickOutput}
    buildCompleteModel={buildCompleteModel}
    extractCompleteOutput={extractCompleteOutput}
    onSelectionChange={onSelectionChange}
    saving={saving}
  />
  <SuperviseSubmitSection
    onLoadingChange={onLoadingChange}
    onSave={onSave}
    onSaveAndStart={onSaveAndStart}
    onCancel={onCancel}
  />
</>;

export const SelectViewMode = ({value, options, onChange}) => <div
  className="form-floating" style={{width: 200}}
>
  <select
    id="select-view-mode" className="form-select"
    onChange={event => onChange(event.target.value)}
    value={value}
  >
    {
      options.map(
        ({value, label}) => <option key={value} value={value}>{label}</option>
      )
    }
  </select>
  <label htmlFor="select-view-mode">View Mode</label>
</div>

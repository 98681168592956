const statusBadgeClasses = {
  'not requested': 'bg-light text-dark border border-dark',
  pending: 'bg-secondary',
  started: 'bg-info',
  warning: 'bg-warning',
  completed: 'bg-success',
  failed: 'bg-danger',
};

export const StageStatusBadge = ({stage, prefix}) => <span
  className={`badge ${statusBadgeClasses[stage.status]} position-relative`}
>
  {prefix && `${prefix}: `}{stage.status.toUpperCase()}
  {
    stage.inconsistencyReasons.length > 0 ? <span
      className="badge bg-warning border border-light rounded-circle p-1 position-absolute top-0 start-100 translate-middle"
      title={`- ${stage.inconsistencyReasons.join('\n- ')}`}
    ><span className="visually-hidden" /></span> : null
  }
</span>

export const ModelStatusBadge = ({model}) => <span
  className={`badge ${statusBadgeClasses[model.style]} position-relative`}
>{model.label}</span>;

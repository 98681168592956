import { Popover } from "bootstrap";
import { Component } from "react";
import { Converter } from "showdown";
import { numberFormat } from 'humanize';

const PredictorHeader = () => <tr>
  <th className="table-col-attribute">Attribute</th>
  <th className="table-col-value">Value</th>
  <th className="table-col-in-input">In the Input</th>
  <th className="table-col-on-internet">On the Internet</th>
  <th className="table-col-interpretation">Value for the attribute is...</th>
</tr>;

function MaybeStroke({ stroke, children }) {
  return stroke ? <del className="text-muted">{children}</del> : children;
}

class PredictorRow extends Component {
  constructor(props) {
    super(props);
    this.converter = new Converter({ tables: true });
  }
  state = { inputAverage: null, internetAverage: null };
  componentDidMount() {
    const row = this.props.row;
    const name = row?.attribute;
    const title = row?.label || name;
    this.setState(
      {
        inputAverage: this.props.row?.value ? (
          `${(this.props.row?.inputAverage * 100).toFixed(2)}%`
        ) : `μ = ${numberFormat(this.props.row?.inputAverage.toFixed(2), 2)}`
      }
    );
    this.setState(
      {
        internetAverage: this.props.row?.value ? (
          `${(this.props.row?.internetAverage * 100).toFixed(2)}%`
        ) : `μ = ${numberFormat(this.props.row?.internetAverage.toFixed(2), 2)}`
      }
    );
    // Popover.getOrCreateInstance(
    //   document.getElementById(`popover-interpretation-${row?.group}-${name}`),
    //   {
    //     trigger: 'hover focus',
    //     title,
    //     content: `
    //       <div class="d-flex">
    //         <strong class="flex-fill me-3">Avg. in the Input</strong>
    //         <span>${this.inputAverage}</span>
    //       </div>
    //       <div class="d-flex">
    //         <strong class="flex-fill me-3">Avg. in the Internet</strong>
    //         <span>${this.internetAverage}</span>
    //       </div>
    //     `,
    //     html: true
    //   }
    // );
    if (!row?.definition) {
      return;
    }
    const content = this.converter.makeHtml(row?.definition || '').replace(
      '<table>',
      '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-attribute-${row?.group}-${name}`),
      { trigger: 'hover focus', title, content, html: true }
    );
  }
  render = () => <tr className={this.props.bg || ''}>
    <td
      className="table-col-attribute"
      id={`popover-attribute-${this.props.row?.group}-${this.props.row?.attribute}`}
      title={this.props.row?.attribute}
    >
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.attribute}
      </MaybeStroke>
    </td>
    <td className="table-col-value">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.value || <em className="text-muted">numeric</em>}
      </MaybeStroke>
    </td>
    <td className="table-col-in-input">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.state.inputAverage}
      </MaybeStroke>
    </td>
    <td className="table-col-on-internet">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.state.internetAverage}
      </MaybeStroke>
    </td>
    <td
      className="table-col-interpretation"
      id={`popover-interpretation-${this.props.row?.group}-${this.props.row?.attribute}`}
      title={
        (Math.abs(this.props.row?.inputAverage) > Math.abs(this.props.row?.internetAverage)) ? (
          `${numberFormat((this.props.row?.inputAverage / this.props.row?.internetAverage).toFixed(2), 2)} times more ${this.props.row?.value ? 'common' : ''} in the input`
        ) : (
          `${numberFormat((this.props.row?.internetAverage / this.props.row?.inputAverage).toFixed(2), 2)} times more ${this.props.row?.value ? 'common' : ''} in the Internet`
        )
      }
    >
      <MaybeStroke stroke={this.props.row?.excluded}>
        {
          (Math.abs(this.props.row?.inputAverage) > Math.abs(this.props.row?.internetAverage)) ? (
            `${numberFormat((this.props.row?.inputAverage / this.props.row?.internetAverage).toFixed(2), 2)} times more ${this.props.row?.value ? 'common' : ''} in the input`
          ) : (
            `${numberFormat((this.props.row?.internetAverage / this.props.row?.inputAverage).toFixed(2), 2)} times more ${this.props.row?.value ? 'common' : ''} in the Internet`
          )
        }
      </MaybeStroke>
    </td>
  </tr>
}

export const FeatureTable = ({ predictors, loading }) => <section>
  <h4 className="border-bottom border-2">Features</h4>
  <table className="table table-sm table-scrollable collapse show">
    <thead><PredictorHeader /></thead>
    <tbody style={{ height: 500 }}>
      {
        predictors?.map(
          (row, i) => <PredictorRow
            key={`simplified-${i}-${row?.attribute}`}
            row={row} loading={loading}
            bg={
              (Math.abs(row?.inputAverage) > Math.abs(row?.internetAverage)) ?
                'table-success' : 'table-danger'
            }
          />
        )
      }
    </tbody>
  </table>
</section>;

import { Component } from "react";
import { FlashMessage } from "./FlashMessage";
import { Loadable } from "./Loadable";
import { FeatureTable } from "./FeatureTable";
import { BACKENDURL, getJson, postJson } from "../api";
import { DownloadIcon } from "./Icons";


export class FeaturesOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {features: null, loading: 0, saving: 0, errorMessage: null};
    this.excludeFeature = this.excludeFeature.bind(this);
  }
  async componentDidMount() {
    this.setState(previousState => ({loading: previousState.loading + 1}));
    const path = `job/${this.props.jobId}/features/json/`;
    while(true) {
      const {features, areDefinite} = await getJson({path, asAdmin: false});
      this.setState(
        previousState => ({features, loading: previousState.loading - 1})
      );
      if (areDefinite) {
        break
      }
    }
  }
  excludeFeature(changedRow) {
    this.setState(
      previousState => {
        const newIsHidden = !changedRow.isHidden;
        previousState.features.filter(
          predictor => changedRow.value === predictor.value
        ).forEach(predictor => predictor.excluded = newIsHidden);
        return previousState;
      }
    );
  }
  async hideFeatures() {
    this.setState({errorMessage: null});
    const path = `job/${this.props.jobId}/hide-features/`;
    const rowsToHide = this.state.features.filter(row => row.isHidden);
    try {
      await postJson({path, body: {rowsToHide}});
    } catch {
      this.setState({errorMessage: 'Server error occurred.'});
      return;
    }
    const to = `/job/${this.props.jobId}`;
    this.props.navigate(to, {state: {successMessage: 'Predictors excluded.'}});
  }
  render = () => <>
    <FlashMessage children={this.state.errorMessage} type="danger" />
    <Loadable
      loading={this.state.loading} label="Features"
      additionalDetails="It may take up to a few minutes..."
    >
      <FeatureTable
        loading={this.state.saving}
        predictors={this.state.features}
      />
      <div className="d-flex justify-content-between">
        <div>
          {/* <button className="btn btn-primary" onClick={() => this.hideFeatures()}>
            Save Hidden Features
          </button> */}
        </div>
        <div className="mt-auto mb-2">
          <div className="d-flex">
            <div className="flex-fill" />
            <a
              className="btn btn-danger"
              href={`${BACKENDURL}/job/${this.props.jobId}/features/file/`}
              rel="noreferrer"
            >Download as CSV <DownloadIcon /></a>
          </div>
        </div>
      </div>
    </Loadable>
  </>
}
import { Component } from 'react';
import { BACKENDURL, getJson, postJson } from '../api';
import { FlashMessage } from './FlashMessage';
import { Loadable } from './Loadable';
import { withRouter } from '../navigation';
import { DownloadIcon, PencilIcon } from './Icons';
import { ModelStatusBadge } from './StatusBadge';
import { PredictorsOverview } from './PredictorsOverview';
import { PercentageProgressBar, QuickModelStatistics } from './QuickModelStatistics';
import { Results } from './Results';
import { areResultsDownloadable } from '../results';
import { JobAdministration } from './JobAdministration';
import { ExtraInformation } from './ExtraInformation';

class JobTitle extends Component {
  state = {
    editing: false, saving: false, error: null,
    draftFilename: this.props.jobMeta?.filename
  }
  downloadInputUrl() {
    return `${BACKENDURL}/job/${this.props.jobMeta?.id}/input/file`;
  }
  downloadResultsUrl() {
    if (process.env.REACT_APP_DEPLOY_MODE !== 'admin') {
      return `${BACKENDURL}/job/${this.props.jobMeta?.id}/completeoutput/results/file`;
    }
    return `${BACKENDURL}/job/${this.props.jobMeta?.id}/completeoutput/results/file/admin`;
  }
  async saveFilenameChange() {
    this.setState({ saving: true });
    const path = `job/${this.props.jobMeta?.id}/change-filename/`;
    const body = { newFilename: this.state.draftFilename };
    try {
      await postJson({ path, body });
      this.props.setFilename(this.state.draftFilename);
      this.setState({ editing: false, saving: false });
    } catch {
      this.setState({ error: "Failed to save filename change.", saving: false });
    }
  }
  render = () => <header>
    <div className="d-flex">
      <div className="flex-fill d-flex">
        {
          this.state.editing ? <>
            <div className="input-group w-50">
              <div className="form-floating d-flex">
                <input
                  type="text" className="form-control"
                  disabled={this.state.saving} value={this.state.draftFilename}
                  onChange={event => this.setState({ draftFilename: event.target.value })}
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                />
                <label>Custom Filename</label>
                <button
                  className="btn btn-outline-secondary"
                  disabled={this.state.saving}
                  style={{ borderRadius: 0 }}
                  onClick={() => this.setState({ editing: false })}
                >Cancel</button>
                <button
                  className="btn btn-outline-danger d-flex align-items-center"
                  disabled={this.state.saving}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  onClick={() => this.saveFilenameChange()}
                >
                  <div>Save</div>
                  <div
                    className={`spinner-border ms-2 ${this.state.saving ? '' : 'd-none'}`}
                    style={{ width: 20, height: 20 }}
                  />
                </button>
              </div>
            </div>
          </> : <>
            <h3
              style={{ cursor: 'pointer' }}
              onClick={
                () => process.env.REACT_APP_DEPLOY_MODE === 'admin' && this.setState({ editing: true })
              }
            >
              {this.props.jobMeta?.filename}
              {
                process.env.REACT_APP_DEPLOY_MODE === 'admin' && <span
                  className="clickable-v1_2 ms-2"
                ><PencilIcon size={20} /></span>
              }
            </h3>
            <div className="flex-fill" />
          </>
        }
      </div>
      <div className="me-3">
        <div className="d-flex">
          <div className="flex-fill" />
          <div>Quick Model</div>
        </div>
        <div className="d-flex">
          <div className="flex-fill" />
          <div>
            {this.props.jobMeta?.models?.quick && <ModelStatusBadge model={this.props.jobMeta?.models?.quick} />}
          </div>
        </div>
      </div>
      <div className="me-3">
        <div className="d-flex">
          <div className="flex-fill" />
          <div>Complete Model</div>
        </div>
        <div className="d-flex">
          <div className="flex-fill" />
          <div>
            {this.props.jobMeta?.models?.complete && <ModelStatusBadge model={this.props.jobMeta?.models?.complete} />}
          </div>
        </div>
        {
          this.props.jobMeta?.models.complete.progress && <div className="mt-1">
            <PercentageProgressBar
              value={this.props.jobMeta?.models.complete.progress}
              color="info"
            />
          </div>
        }
      </div>
      <div>
        <a
          className="btn btn-secondary btn-lg me-2"
          href={this.downloadInputUrl()}
        >
          Download Input File <DownloadIcon />
        </a>
      </div>
      {
        areResultsDownloadable(this.props.jobMeta) && <div>
          <a className="btn btn-danger btn-lg" href={this.downloadResultsUrl()}>
            Download Complete Results <DownloadIcon />
          </a>
        </div>
      }
    </div>
  </header>
}


const JobOverview = ({ jobId, jobMeta, navigate, synchronizeJobMeta }) => <>
  <ul className="nav nav-tabs">
    <li className="nav-item active">
      <button
        className="nav-link active"
        data-bs-toggle="tab"
        data-bs-target="#tab-quick-model"
      >Quick Model</button>
    </li>
    <li className="nav-item">
      <button
        className={`nav-link ${jobMeta.models.complete.label !== "OUTPUT BUILT" ? 'disabled' : ''}`}
        data-bs-toggle="tab"
        data-bs-target="#tab-results"
      >Results</button>
    </li>
    {
      (
        process.env.REACT_APP_DEPLOY_MODE === 'admin' || jobMeta.extraInformation
      ) && <li className="nav-item">
        <button
          className="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#tab-extra-information"
        >Description</button>
      </li>
    }
    {
      process.env.REACT_APP_DEPLOY_MODE === 'admin' && <li className="nav-item">
        <button
          className="nav-link"
          data-bs-toggle="tab"
          data-bs-target="#tab-administration"
        >Administration</button>
      </li>
    }
  </ul>
  <div className="tab-content p-2 w-100">
    <div id="tab-quick-model" className="tab-pane fade show active w-100">
      <div className="d-flex align-items-start">
        <div className="nav flex-column nav-pills me-3" style={{ minWidth: 180 }}>
          <button
            className="nav-link active"
            data-bs-toggle="pill"
            data-bs-target="#tab-quick-model-statistics"
          >Statistics</button>
          <button
            className="nav-link"
            data-bs-toggle="pill"
            data-bs-target="#tab-quick-model-predictors"
          >Predictors</button>
        </div>
        <div className="tab-content w-100">
          <div
            id="tab-quick-model-statistics"
            className="tab-pane fade show active w-100"
          >
            {
              ['MODEL BUILT', 'BUILDING OUTPUT', 'OUTPUT BUILT'].includes(
                jobMeta?.models.quick.label
              ) && <QuickModelStatistics jobId={jobId} />
            }
          </div>
          <div
            id="tab-quick-model-predictors"
            className="tab-pane fade show"
          >
            <PredictorsOverview jobId={jobId} navigate={navigate} />
          </div>
        </div>
      </div>
    </div>
    <div id="tab-results" className="tab-pane fade">
      {
        jobMeta?.models.complete.label === 'OUTPUT BUILT' &&
        <Results jobId={jobId} jobMeta={jobMeta} />
      }
    </div>
    {
      (
        process.env.REACT_APP_DEPLOY_MODE === 'admin' || jobMeta.extraInformation
      ) && <div id="tab-extra-information" className="tab-pane fade">
        <ExtraInformation
          jobId={jobId}
          data={jobMeta?.extraInformation}
          onSave={synchronizeJobMeta}
        />
      </div>
    }
    <div id="tab-administration" className="tab-pane fade">
      <JobAdministration
        jobId={jobId}
        jobMeta={jobMeta}
        onSave={synchronizeJobMeta}
      />
    </div>
  </div>
</>;

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = { jobMeta: null, loading: 1 };
  }
  async componentDidMount() {
    const jobId = this.props.routerFeatures.params.jobId;
    while (true) {
      const path = `job/${jobId}/meta/${this.state.jobMeta?.metaHash}/`;
      const jobMeta = await getJson({ path, asAdmin: false });
      this.setState({ jobMeta, loading: 0 });
    }
  }
  synchronizeJobMeta = (
    {canRegularUserDownloadResults, emailToNotify, extraInformation}
  ) => this.setState(
    previous => {
      if (canRegularUserDownloadResults !== undefined) {
        previous.canRegularUserDownloadResults = canRegularUserDownloadResults;
      }
      if (emailToNotify !== undefined) {
        previous.emailToNotify = emailToNotify;
      }
      if (extraInformation !== undefined) {
        previous.extraInformation = extraInformation;
      }
      return previous;
    }
  )
  setFilename = value => this.setState(
    previousState => ({ jobMeta: { ...previousState.jobMeta, filename: value } })
  )
  render = () => <Loadable label="Job Meta" loading={this.state.loading}>
    {
      this.state.jobMeta ? <>
        <JobTitle
          jobMeta={this.state.jobMeta}
          setFilename={value => this.setFilename(value)}
        />
        <FlashMessage
          children={this.props.routerFeatures.location.state?.successMessage}
          type="success"
        />
        <JobOverview
          jobId={this.props.routerFeatures.params.jobId}
          jobMeta={this.state.jobMeta}
          navigate={this.props.routerFeatures.navigate}
          synchronizeJobMeta={data => this.synchronizeJobMeta(data)}
        />
      </> : <h4>Couldn't get the job.</h4>
    }
  </Loadable>
}

export const JobWithRouter = withRouter(Job);

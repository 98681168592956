import { Component } from "react";
import { post } from "../api";
import { FlashMessage } from "./FlashMessage";
import { Checkbox, FileUploader, Submit } from "./Form";
import { withRouter } from "../navigation";
import { JobStartupStatusModal } from "./Modal";

export class NewJob extends Component {
  state = {
    loading: 0,
    selectedFile: null,
    emailToNotify: '',
    extractQuickOutput: true,
    buildCompleteModel: true,
    extractCompleteOutput: true,
    canRegularUserDownloadResults: false,
    succeeded: false, errorMessage: null,
    enrichmentComparedStatistics: null,
    submitted: false, jobId: null, analysisStarted: false
  }
  handleFileChange = file => this.setState({selectedFile: file})
  async submit() {
    this.setState({errorMessage: null});
    if (this.state.selectedFile === null) {
      this.setState({errorMessage: 'No file selected.'});
      return false;
    }
    if (this.state.selectedFile.size > 1024 * 1024 * 50) {
      this.setState({errorMessage: 'File too large. Allowed size is up to 50 MB.'});
      return false;
    }
    this.setState({submitted: true});
    const body = new FormData();
    if (this.state.buildCompleteModel && this.state.extractCompleteOutput && this.state.emailToNotify) {
      body.append('emailtonotify', this.state.emailToNotify);
    }
    body.append(
      'extractquickoutput', this.state.extractQuickOutput
    );
    body.append(
      'validatequickmodel', this.state.buildCompleteModel
    );
    body.append(
      'extractcompleteoutput',
      this.state.buildCompleteModel && this.state.extractCompleteOutput
    );
    body.append(
      'can_regular_user_download_results',
      this.state.buildCompleteModel && this.state.extractCompleteOutput
    );  
    body.append('data', this.state.selectedFile);
    let responseReader = null;
    try {
      responseReader = (await post({path:'job/', body})).body.getReader();
    } catch {
      this.setState({errorMessage: 'Server error occurred.'});
      return;
    }
    let allSnapshots = [];
    const decoder = new TextDecoder();
    const jobId = decoder.decode((await responseReader.read()).value, {stream: true});
    this.setState({jobId});
    let recentSnapshot = [];
    let partialRow = '';
    while (true) {
      const { done, value } = await responseReader.read();
      this.setState({analysisStarted: true});
      if (done) break;
      if (!value) continue;
      const rows = decoder.decode(value, {stream: true}).split('\n');
      rows[0] = partialRow + rows[0];
      partialRow = rows.pop();
      recentSnapshot = [
        ...recentSnapshot, ...rows.map(
          row => {
            const [attributeValue, weight] = row.split('|');
            const [attribute, value] = attributeValue.split(/__[A-Z]_/);
            return {attribute, value: value || null, weight: parseInt(weight)};
          }
        )
      ];
      const snapshots = [];
      while (recentSnapshot.length > 10) {
        snapshots.push(recentSnapshot.slice(0, 10));
        recentSnapshot = recentSnapshot.slice(10);
      }
      allSnapshots = [...allSnapshots, ...snapshots];
    }
    this.setState({enrichmentComparedStatistics: allSnapshots});
    // const successMessage = `Job ${this.state.selectedFile.name} created successfully.`;
    // this.props.routerFeatures.navigate('/v1.3/', {state: {successMessage}});
  }
  render = () => <>
    <JobStartupStatusModal
      submitted={this.state.submitted} jobId={this.state.jobId}
      analysisStarted={this.state.analysisStarted}
      comparedStatistics={this.state.enrichmentComparedStatistics}
    />
    <h3>Create a New Job</h3>
    <FlashMessage type="danger" children={this.state.errorMessage} />
    <FileUploader
      setSelectedFile={file => this.setState({selectedFile: file})}
      disabled={this.state.loading}
    />
    <div className="text-muted mb-3">
      <em>Recommended input size between 500 and 10,000 domains.</em>
    </div>
    <Checkbox
      value={this.state.extractQuickOutput}
      onValueChange={value => this.setState({extractQuickOutput: value})}
      label="Extract Quick Output" disabled={this.state.loading}
    />
    <Checkbox
      value={this.state.buildCompleteModel}
      onValueChange={value => this.setState({buildCompleteModel: value})}
      label="Build Complete Model" disabled={this.state.loading}
    />
    <Checkbox
      value={this.state.buildCompleteModel && this.state.extractCompleteOutput}
      onValueChange={value => this.setState({extractCompleteOutput: value})}
      disabled={!this.state.buildCompleteModel || this.state.loading}
      label="Extract Complete Output"
    />
    <div className="form-floating my-3">
      <input
        id="email" type="email" className="form-control"
        placeholder="E-mail address to notify" disabled={this.state.loading}
        value={this.state.emailToNotify}
        onChange={e => this.setState({emailToNotify: e.target.value})}
      />
      <label htmlFor="email">E-mail address to notify</label>
    </div>
    <div className="mb-4">
      <Checkbox
        value={this.state.canRegularUserDownloadResults}
        onValueChange={value => this.setState({canRegularUserDownloadResults: value})}
        disabled={this.state.loading}
        label="Can Regular User Download Results"
      />
    </div>
    <Submit
      className="d-block" loading={this.state.loading}
      onClick={() => this.submit()}
      setLoading={() => null && this.setState(previousState => ({loading: previousState.loading + 1}))}
      unsetLoading={() => null && this.setState(previousState => ({loading: previousState.loading - 1}))}
    />
  </>
}

export const NewJobWithRouter = withRouter(NewJob);

import { Route } from "react-router-dom";
import { BootstrapNavLink, withRouter } from "./navigation";
import { IndexWithRouter } from "./components/Index";
import { JobWithRouter } from "./components/Job";
import { NewJobWithRouter } from "./components/NewJob";
import { Changelog } from "./components/Changelog";

const version = 'v1.5';

export const NavbarItems = () => process.env.REACT_APP_DEPLOY_MODE === 'admin' && <>
  {withRouter(BootstrapNavLink)({to: `/${version}/`, label: "Home"})}
  {withRouter(BootstrapNavLink)({to: `/${version}/new-job`, label: "New Job"})}
</>;

export const SimDomainsRoutes = () => <>
  {
    process.env.REACT_APP_DEPLOY_MODE === 'admin' && <>
      <Route path={`/${version}/`} element={<IndexWithRouter />} />
      <Route path={`/${version}/new-job`} element={<NewJobWithRouter />} />
      <Route path={`/${version}/changelog`} element={<Changelog />} />
    </>
  }
  <Route path={`/${version}/job/:jobId`} element={<JobWithRouter />} />
</>;

const B = Object.fromEntries(
  Object.entries(process.env).filter(
    ([key,]) => key.startsWith('REACT_APP_BACKEND_')
  ).map(([key, value]) => [key.slice(18), value])
);

export const BACKENDURL = `${B.SCHEME}://${B.HOST}:${B.PORT}/${B.ROOT}/v1.5`;

const authHeader = () => (
  {Authorization: `Basic ${window.btoa(`${B.USER}:${B.PASS}`)}`}
);

export async function get({path, asAdmin}) {
  const url = `${B.SCHEME}://${B.HOST}:${B.PORT}/${B.ROOT}/v1.5/${path}`;
  if (!asAdmin) return await fetch(url);
  return await fetch(url, {headers: authHeader()});
}

export async function post({path, body, extraHeaders}) {
  const url = `${B.SCHEME}://${B.HOST}:${B.PORT}/${B.ROOT}/v1.5/${path}`;
  const headers = {...authHeader(), ...(extraHeaders || {})};
  return await fetch(url, {headers, method: 'POST', body});
}

export async function getJson({path, asAdmin}) {
  const response = await get({path, asAdmin});
  if (response.ok) return await response.json();
  throw new Error();
}

export async function postJson({path, body}) {
  const extraHeaders = {'Content-Type': 'application/json'};
  const response = await post({path, body: JSON.stringify(body), extraHeaders});
  if (response.ok) return await response.json();
  throw new Error();
}

export async function delete_({path}) {
  const url = `${B.SCHEME}://${B.HOST}:${B.PORT}/${B.ROOT}/v1.5/${path}`;
  return await fetch(url, {headers: authHeader(), method: 'DELETE'});
}

export async function deleteJson({path, asAdmin}) {
  const response = await delete_({path, asAdmin});
  if (response.ok) return await response.json();
  throw new Error();
}

import { Component } from "react";
import { postForm } from "../api";
import { FlashMessage } from "./FlashMessage";
import { Checkbox, FileUploader, Submit } from "./Form";
import { withRouter } from "../navigation";

export class NewJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      selectedFile: null,
      emailToNotify: '',
      extractQuickOutput: true,
      buildCompleteModel: true,
      extractCompleteOutput: true,
      canRegularUserDownloadResults: false,
      succeeded: false, errorMessage: null
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.submit = this.submit.bind(this);
  }
  handleFileChange(file) { this.setState({selectedFile: file}); }
  async submit() {
    if (this.state.selectedFile === null) {
      this.setState({errorMessage: 'No file selected.'});
      return false;
    }
    if (this.state.selectedFile.size > 1024 * 1024 * 50) {
      this.setState({errorMessage: 'File too large. Allowed size is up to 50 MB.'});
      return false;
    }
    const body = new FormData();
    if (this.state.buildCompleteModel && this.state.extractCompleteOutput && this.state.emailToNotify) {
      body.append('emailtonotify', this.state.emailToNotify);
    }
    body.append(
      'extractquickoutput', this.state.extractQuickOutput
    );
    body.append(
      'validatequickmodel', this.state.buildCompleteModel
    );
    body.append(
      'extractcompleteoutput',
      this.state.buildCompleteModel && this.state.extractCompleteOutput
    );
    body.append(
      'can_regular_user_download_results',
      this.state.buildCompleteModel && this.state.extractCompleteOutput
    );  
    body.append('data', this.state.selectedFile);
    try {
      await postForm({path:'job/', body});
    } catch {
      this.setState({errorMessage: 'Server error occurred.'});
      return;
    }
    const successMessage = `Job ${this.state.selectedFile.name} created successfully.`;
    this.props.routerFeatures.navigate('/v1.2/', {state: {successMessage}});
  }
  render = () => <>
    <h3>Create a New Job</h3>
    <FlashMessage type="danger" children={this.state.errorMessage} />
    <FileUploader
      setSelectedFile={this.handleFileChange} disabled={this.state.loading}
    />
    <div className="text-muted mb-3">
      <em>Recommended input size between 500 and 10,000 domains.</em>
    </div>
    <Checkbox
      value={this.state.extractQuickOutput}
      onValueChange={value => this.setState({extractQuickOutput: value})}
      label="Extract Quick Output" disabled={this.state.loading}
    />
    <Checkbox
      value={this.state.buildCompleteModel}
      onValueChange={value => this.setState({buildCompleteModel: value})}
      label="Build Complete Model" disabled={this.state.loading}
    />
    <Checkbox
      value={this.state.buildCompleteModel && this.state.extractCompleteOutput}
      onValueChange={value => this.setState({extractCompleteOutput: value})}
      disabled={!this.state.buildCompleteModel || this.state.loading}
      label="Extract Complete Output"
    />
    <div className="form-floating my-3">
      <input
        id="email" type="email" className="form-control"
        placeholder="E-mail address to notify" disabled={this.state.loading}
        value={this.state.emailToNotify} onChange={e => this.setState({emailToNotify: e.target.value})}
      />
      <label htmlFor="email">E-mail address to notify</label>
    </div>
    <div className="mb-4">
      <Checkbox
        value={this.state.canRegularUserDownloadResults}
        onValueChange={value => this.setState({canRegularUserDownloadResults: value})}
        disabled={this.state.loading}
        label="Can Regular User Download Results"
      />
    </div>
    <Submit
      className="d-block" onClick={this.submit} loading={this.state.loading}
      setLoading={() => this.setState(previousState => ({loading: previousState.loading + 1}))}
      unsetLoading={() => this.setState(previousState => ({loading: previousState.loading - 1}))}
    />
  </>
}

export const NewJobWithRouter = withRouter(NewJob);

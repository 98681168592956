import { Component } from "react";
import ApexCharts from "apexcharts";
import { uuid } from "../uuid";
import { numberFormat } from 'humanize';

export class ResultFrequencyChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.coreId = `results-chart-${uuid()}`;
  }
  select({series: [{data},]}, cutOff) {
    this.chart.clearAnnotations();
    const newCutOffScore = data[cutOff][0];
    const color = {opacity: 0.5, fillColor: '#ffe4e1', borderColor: '#dc3545'};
    this.chart.addXaxisAnnotation({x: -1, x2: newCutOffScore, ...color});
    if (this.props.data.threshold) {
      this.chart.addXaxisAnnotation(
        {
          x: -this.props.data.threshold, borderColor: '#dc3545',
          label: {
            text: `Threshold: ${Number(this.props.data.threshold.toFixed(3))}`,
            borderColor: '#dc3545',
            style: {background: '#dc3545', color: '#fff', fontWeight: 600}
          },
        }
      );
    }
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(newCutOffScore);
    }
  }
  updateSelection(config) {
    const currentIndex = config.series[0].data.findLastIndex(
      entry => entry[0] >= this.props.cutOffScore
    );
    this.select(config, currentIndex);
  }
  formatScoreRange(value) {
    const data = this.props.data.frequenciesInIntervals;
    const next = data.find(entry => entry.leftScore > -value)?.leftScore || 1;
    return `${-value} < <strong>score</strong> ⩽ ${next}`
  }
  counts = [
    {
      name: "Cumulative Counts", color: "#dc3545", yaxisOpposite: true,
      data: this.props.data.frequenciesInIntervals.map(
        (entry, i) => [
          -entry.leftScore,
          this.props.data.frequenciesInIntervals.slice(i).reduce(
            (acc, entry) => acc + entry.value, 0
          )
        ]
      ),
    },
    {
      name: "Count per Score Range", color: '#6c757d', yaxisOpposite: false,
      data: this.props.data.frequenciesInIntervals.map(
        entry => [-entry.leftScore, entry.value]
      ),
    }
  ]
  series = this.counts.map(entry => ({name: entry.name, data: entry.data}))
  xaxis = {
    type: 'numeric', min: -1, max: -.5, title: {text: 'Score'},
    decimalsInFloat: 3, labels: {formatter: value => -value},
  }
  yaxis = this.counts.map(
    entry => (
      {
        title: {text: entry.name, style: {color: entry.color}},
        opposite: entry.yaxisOpposite, seriesName: entry.name,
        labels: {formatter: value => numberFormat(value, 0)},
      }
    )
  )
  chartOptions = {
    type: 'area', height: 450,
    events: {
      updated: (...[, {config}]) => this.updateSelection(config),
      mounted: (...[, {config}]) => this.updateSelection(config),
      click: (...[,, {config, dataPointIndex}]) => (
        dataPointIndex >= 0 && this.select(config, dataPointIndex)
      ),
      beforeZoom: (...[, {xaxis}]) => (
        {xaxis: {min: -1, max: Math.min(xaxis.max, -.5)}}
      ),
    },
    toolbar: {
      tools: {
        download: false, pan: false, selection: false,
        reset: false, zoom: false, zoomin: true, zoomout: true,
        customIcons: [
          {
            icon: `
              <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24" viewBox="0 0 24 24" width="24">
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z"></path>
              </svg>
            `,
            class: 'apexcharts-zoom-icon',
            title: 'Zoom to Threshold',
            click: () => this.chart.zoomX(-1, -this.props.data.threshold),
          },
          {
            icon: `
              <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            `,
            class: 'apexcharts-reset-icon',
            title: 'Reset Zoom',
            click: () => this.chart.zoomX(-1, -.5)
          }
        ]
      },
      offsetX: -100, offsetY: 0,
    },
  }
  options = {
    dataLabels: {enabled: false}, colors: ['#dc3545', '#6c757d'],
    chart: this.chartOptions,
    series: this.series, xaxis: this.xaxis, yaxis: this.yaxis,
    tooltip: {x: {formatter: value => this.formatScoreRange(value)}},
  }
  componentDidMount() {
    this.chart = new ApexCharts(window[this.coreId], this.options);
    this.chart.render();
  }
  render = () => <div id={this.coreId} className={this.props.className} />
}

import { Popover } from "bootstrap";
import { Component } from "react";
import { Converter } from "showdown";
import { Checkbox } from "./Form";

const PredictorHeader = () => <tr>
  <th className="table-col-attribute">Attribute</th>
  <th className="table-col-type">Type</th>
  <th className="table-col-relation">Relation</th>
  <th className="table-col-value">Value</th>
  <th className="table-col-weight">Weight</th>
  {process.env.REACT_APP_DEPLOY_MODE === 'admin' && <th className="table-col-use">Use</th>}
</tr>;

function MaybeStroke({stroke, children}) {
  return stroke ? <del className="text-muted">{children}</del> : children;
}

class PredictorRow extends Component {
  constructor(props) {
    super(props);
    this.converter = new Converter({tables: true});
  }
  componentDidMount() {
    const row = this.props.row;
    if (!row?.definition) {
      return;
    }
    const name = row?.attribute;
    const title = row?.label || name;
    const content = this.converter.makeHtml(row?.definition || '').replace(
      '<table>',
      '<table class="table table-sm" style="white-space: nowrap; width: 1%">'
    );
    Popover.getOrCreateInstance(
      document.getElementById(`popover-attribute-${row?.group}-${name}`),
      {trigger: 'hover focus', title, content, html: true}
    );
  }
  render = () => <tr>
    <td
      className="table-col-attribute"
      id={`popover-attribute-${this.props.row?.group}-${this.props.row?.attribute}`}
      title={this.props.row?.attribute}
    >
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.attribute}
      </MaybeStroke>
    </td>
    <td className="table-col-type">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.type}
      </MaybeStroke>
    </td>
    <td className="table-col-relation">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.value?.relation || 'is'}
      </MaybeStroke>
    </td>
    <td className="table-col-value">
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.value?.value || <em className="text-muted">Missing</em>}
      </MaybeStroke>
    </td>
    <td
      className="table-col-weight"
      title={this.props.row?.weight}
    >
      <MaybeStroke stroke={this.props.row?.excluded}>
        {this.props.row?.weight}
      </MaybeStroke>
    </td>
    {
      process.env.REACT_APP_DEPLOY_MODE === 'admin' && <td className="table-col-use">
        <Checkbox
          value={!this.props.row?.excluded}
          onValueChange={this.props.onSelectionChange}
          disabled={this.props.loading}
        />
      </td>
    }
  </tr>
}

export const PredictorTable = ({title, predictors, onExcludePredictor, loading}) => <section>
  <h4 className="border-bottom border-2">{title} Predictors</h4>
  <table className="table table-sm table-scrollable collapse show">
    <thead><PredictorHeader /></thead>
    <tbody style={{height: 170}}>
      {
        predictors?.map(
          (row, i) => <PredictorRow
            key={`${title}-${i}-${row?.attribute}`} row={row} loading={loading}
            onSelectionChange={() => onExcludePredictor(row)}
          />
        )
      }
    </tbody>
  </table>
</section>;
